.PodcastPlayer {
    margin: auto;
    border: #e97a29 outset 2px;
    border-radius: 5px;
    background-color: #232938;
    padding-top: 10px;
    color: lightgray;
}

.PodcastPlayer-title {
    color: #dfa440;
    padding-top: 10px;
}

.PodcastPlayer-title-date {
    color: #dfa440;
    padding-bottom: 10px;
}

.PodcastPlayer-subtitle {
    color: #e97a29;
    width: 100%;
    text-align: left;
    padding-left: 10px;
    padding-right: 10px;
}

.PodcastPlayer-description {
    padding: 10px;
    text-align: left;
}

.rhap_container {
    background-color: black;
}

.rhap_time {
    color: #dfa440;
}

.rhap_progress-bar-show-download {
    background-color: white;
}

.rhap_progress-filled {
    background-color: #e97a29;
}

.rhap_download-progress {
    background-color: #dfa440;
}

.rhap_main-controls-button, .rhap_volume-button, .rhap_button-clear {
    color: #dfa440;
}

.rhap_main-controls-button:hover, .rhap_volume-button:hover, .rhap_button-clear:hover {
    color: #e97a29;
}
