.Footer {
    background-color: black;
    padding: 10px;
    text-align: left;
}

.Footer-donations {
    margin: 5px 5px 20px 5px;
    padding: 10px;
    border: #e97a29 outset 2px;
    border-radius: 5px;
    background-color: #232938;
    color: #dfa440;
}

.Footer-text {
    font-size: smaller;
    color: #232938;
}

.Footer-svg {
    fill: #dfa440;
}

.Footer-svg:hover {
    cursor: pointer;
    fill: #e97a29;
}

.Footer-link {
    color: #dfa440;
}

.Footer-donations-link {
    color: white;
}

.Footer-link:hover, .Footer-donations-link:hover {
    color: #e97a29;
}
